import React from 'react'
import { Link } from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll';
import ArrowRight from "../../images/arrow-right.inline.svg";
import styles from './contentBlock.module.scss';

class ContentBlock extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <div className={`row ${styles.contentBlock}`}>
        <div className={"col-sm-12 col-md-12 col-lg-6 " + (this.props.imageCol === 'left' ? 'order-sm-1 order-md-1 order-lg-2': 'order-sm-2 order-md-2')}>
          <ScrollAnimation className="d-lg-flex dude flex-lg-column justify-content-lg-center" animateIn="fadeIn" animateOnce={true} delay={300}>
            <h2 className="mb-4">{this.props.title}</h2>
            <p>{this.props.copy}</p>
            {
              this.props.link ? (
                <Link to={this.props.link}>
                  <div>
                    Learn More
                    <ArrowRight />
                  </div>
                </Link>
              ) : null
            }
          </ScrollAnimation>
        </div>
        <div className={"col-sm-12 col-md-12 col-lg-6 " + (this.props.imageCol === 'left' ? `order-sm-2 order-md-2 order-lg-1 ${styles.left}`: `order-sm-1 order-md-2 ${styles.right}`)}>
          <ScrollAnimation animateIn={this.props.imageCol === 'left' ? 'fadeInLeft' : 'fadeInRight'} animateOnce={true}>
            {/* Hacky solution currently to have dynamic bg images. Image must be in /static/images. Name is passed in as prop */}
            <div className={styles.imageContainer} style={{backgroundImage: this.props.imageName ? `url(/images/${this.props.imageName})` : ''}}></div>
          </ScrollAnimation>
        </div>
      </div>
    )
  }
}
export default ContentBlock