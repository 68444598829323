import React from "react"
import Helmet from 'react-helmet'
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Heading from "../../components/heading/heading";
import ContentBlock from "../../components/contentBlock/contentBlock";
import ScrollAnimation from 'react-animate-on-scroll';
import Img from "gatsby-image";

const RestaurantPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Restaurant" />
      <Helmet bodyAttributes={{class: 'overflow-x-hidden'}} />
      <ScrollAnimation className="product-page-header mt-5" animateIn="fadeInUp" animateOnce={true}>
        <Heading
          title="Restaurant"
          copy="The RetailStack Commerce Platform for Restaurants offers everything you need to operate your restaurant in a single platform. The cloud-based platform is designed with an enterprise in mind, allowing both franchise and corporate restaurants to manage multiple units, while supporting restaurant staff with intuitive design, continual innovation and robust partner integration options."
          align="center"
        />
        <Img fluid={data.file.childImageSharp.fluid} alt="restaurant header" />
      </ScrollAnimation>
      <ContentBlock
        imageCol="right"
        title="Scale With Speed"
        copy="The RetailStack Commerce Platform isn’t just a traditional instore point of sale, but also a holistic system supporting rapid expansion across multiple locations or brand concepts. The platform supports a variety of restaurant concepts, including QSR, fine dining and delivery-first - with solutions for both on and off premise sales."
        imageName="restaurant-supports-every-aspect.png"
      />
      <ContentBlock
        imageCol="left"
        title="First and Third Party Delivery"
        copy="Whether you’re operating your own delivery business or partnering with a delivery service provider, the RetailStack Commerce Platform allows restaurants direct and seamless integration for operating your delivery program. With support for both consumer-facing innovations like driver tracking or support for marketplace sales, the RetailStack Commerce Platform delivers."
        imageName="restaurant-delivery.png"
      />
      <ContentBlock
        imageCol="right"
        title="Online and Mobile Ordering"
        copy="With consumers on the go, online and mobile ordering are fast becoming table stakes for restaurant operations. However, with other enterprise point of sale systems, integrations to achieve digital sales are costly, difficult and inflexible. The RetailStack Commerce Platform reimagines this dynamic by integrating both in store and above store sales. With open APIs and mobile SDKs, integration between your instore and online sales is seamless and easy."
        imageName="restaurant-online-ordering.png"
      />
      <ContentBlock
        imageCol="left"
        title="Integrated Loyalty and Rewards"
        copy="The RetailStack Commerce Platform comes with out-of-the-box loyalty and rewards capabilities - allowing you to operate your own customizable points based loyalty program more quickly and without additional incremental costs. With built in rewards capabilities you can drive consumer loyalty through programmed and surprise-and-delight rewards. If you have an existing provider, the platform supports easy integrations through open APIs."
        imageName="restaurant-loyalty-platform.png"
      />
      <ContentBlock
        imageCol="right"
        title="Restaurant Integrations"
        copy="The RetailStack Commerce Platform supports robust store management, configuration and reporting options through Enterprise Back Office. The platform also supports a wide variety of both hardware and software integrations such as customer-facing displays and KDS, a holistic prep labeling system and marketing automation integrations."
        imageName="restaurant-prep-labeling.png"
        link="/industries/enterprise"
      />
    </Layout>
  )
}

export default RestaurantPage

export const query = graphql`
  query {
    file(relativePath: { eq: "restaurant-pos-header.png" }) {
      childImageSharp {
        fluid(maxWidth: 1170) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`